export default {
  computed: {
    marksButtons() {
      return [
        {
          icon: "mdi-format-bold",
          label: this.$t("textEditor.buttons.bold"),
          shortcut: `${this.controlKey} + B`,
          active: this.editor?.isActive("bold"),
          value: "bold",
          action: () => this.editor.chain().focus().toggleBold().run(),
          featureEnabled: true,
        },
        {
          icon: "mdi-format-italic",
          label: this.$t("textEditor.buttons.italic"),
          shortcut: `${this.controlKey} + I`,
          active: this.editor?.isActive("italic"),
          value: "italic",
          action: () => this.editor.chain().focus().toggleItalic().run(),
          featureEnabled: true,
        },
        {
          icon: "mdi-format-underline",
          label: this.$t("textEditor.buttons.underline"),
          shortcut: `${this.controlKey} + U`,
          active: this.editor?.isActive("underline"),
          value: "underline",
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          featureEnabled: true,
        },
        {
          icon: "mdi-format-strikethrough",
          label: this.$t("textEditor.buttons.strikethrough"),
          shortcut: `${this.controlKey} + ${this.$t("keyboard.shift")} + X`,
          active: this.editor?.isActive("strike"),
          value: "strikethrough",
          action: () => this.editor.chain().focus().toggleStrike().run(),
          featureEnabled: true,
        },
        {
          icon: "mdi-code-tags",
          label: this.$t("textEditor.buttons.inlineCode"),
          shortcut: `${this.controlKey} + E`,
          active: this.editor?.isActive("code"),
          value: "inlineCode",
          action: () => this.editor.chain().focus().toggleCode().run(),
          featureEnabled: this.featureCode,
        },
        this.mathBlock,
      ].filter((button) => button.featureEnabled)
    },

    nodesButtons() {
      return [
        {
          icon: "mdi-format-text",
          label: this.$t("textEditor.buttons.text"),
          shortcut: `${this.controlKey} + Alt + 0`,
          active: this.editor?.isActive("paragraph") &&
                  !this.editor?.isActive("orderedList") &&
                  !this.editor?.isActive("bulletList"),
          value: "text",
          action: () => this.editor.chain().focus().setParagraph().run(),
          canConvertSelection: true,
          featureEnabled: true,
        },
        {
          icon: "mdi-format-header-1",
          label: this.$t("textEditor.buttons.heading1"),
          shortcut: `${this.controlKey} + Alt + 1`,
          active: this.editor?.isActive("heading", { level: 1 }),
          value: "heading1",
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          canConvertSelection: true,
          featureEnabled: true,
        },
        {
          icon: "mdi-format-header-2",
          label: this.$t("textEditor.buttons.heading2"),
          shortcut: `${this.controlKey} + Alt + 2`,
          active: this.editor?.isActive("heading", { level: 2 }),
          value: "heading2",
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          canConvertSelection: true,
          featureEnabled: true,
        },
        {
          icon: "mdi-format-header-3",
          label: this.$t("textEditor.buttons.heading3"),
          shortcut: `${this.controlKey} + Alt + 3`,
          active: this.editor?.isActive("heading", { level: 3 }),
          value: "heading3",
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          canConvertSelection: true,
          featureEnabled: true,
        },
        {
          icon: "mdi-format-list-bulleted",
          label: this.$t("textEditor.buttons.bulletList"),
          shortcut: `${this.controlKey} + ${this.$t("keyboard.shift")} + 8`,
          active: this.editor?.isActive("bulletList"),
          value: "bulletList",
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          canConvertSelection: true,
          featureEnabled: true,
        },
        {
          icon: "mdi-format-list-numbered",
          label: this.$t("textEditor.buttons.orderedList"),
          shortcut: `${this.controlKey} + ${this.$t("keyboard.shift")} + 7`,
          active: this.editor?.isActive("orderedList"),
          value: "orderedList",
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          canConvertSelection: true,
          featureEnabled: true,
        },
        {
          icon: "mdi-code-block-tags",
          label: this.$t("textEditor.buttons.codeBlock"),
          shortcut: `${this.controlKey} + ${this.$t("keyboard.shift")} + C`,
          active: this.editor?.isActive("codeBlock"),
          value: "codeBlock",
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
          canConvertSelection: true,
          featureEnabled: this.featureCode,
        },
        this.mathBlock,
        {
          icon: "mdi-table-large-plus",
          label: this.$t("textEditor.buttons.insertTable"),
          active: false,
          value: "insertTable",
          action: () => this.createTableDialog = true,
          canConvertSelection: false,
          featureEnabled: this.featureTable,
        },
      ].filter((button) => button.featureEnabled)
    },

    mathBlock() {
      return  {
        icon: "mdi-sigma",
        label: this.$t("textEditor.buttons.insertMathematic"),
        active: false,
        value: "insertMathematic",
        action: () => {
          this
            .editor
            .chain()
            .focus()
            .insertContent(`$${this.currentSelectionContent()}$`)
            .run()
          this
            .editor
            .chain()
            .focus()
            .setTextSelection(this.currentCaretPosition() - 1 )
            .run()
        },
        canConvertSelection: false,
        featureEnabled: this.featureMath,
      }
    },

    tablesButtons() {
      if(!this.featureTable) return []

      return [
        {
          icon: "mdi-table-column-plus-before",
          label: this.$t("textEditor.buttons.table.addColumnBefore"),
          active: false,
          value: "addColumnBefore",
          action: () => this.editor.chain().focus().addColumnBefore().run(),
          visible: this.editor?.can()?.addColumnBefore(),
        },
        {
          icon: "mdi-table-column-plus-after",
          label: this.$t("textEditor.buttons.table.addColumnAfter"),
          active: false,
          value: "addColumnAfter",
          action: () => this.editor.chain().focus().addColumnAfter().run(),
          visible: this.editor?.can()?.addColumnAfter(),
        },
        {
          icon: "mdi-table-column-remove",
          label: this.$t("textEditor.buttons.table.deleteColumn"),
          active: false,
          value: "deleteColumn",
          action: () => this.editor.chain().focus().deleteColumn().run(),
          visible: this.editor?.can()?.deleteColumn(),
        },
        {
          icon: "mdi-table-row-plus-before",
          label: this.$t("textEditor.buttons.table.addRowBefore"),
          active: false,
          value: "addRowBefore",
          action: () => this.editor.chain().focus().addRowBefore().run(),
          visible: this.editor?.can()?.addRowBefore(),
        },
        {
          icon: "mdi-table-row-plus-after",
          label: this.$t("textEditor.buttons.table.addRowAfter"),
          active: false,
          value: "addRowAfter",
          action: () => this.editor.chain().focus().addRowAfter().run(),
          visible: this.editor?.can()?.addRowAfter(),
        },
        {
          icon: "mdi-table-row-remove",
          label: this.$t("textEditor.buttons.table.deleteRow"),
          active: false,
          value: "deleteRow",
          action: () => this.editor.chain().focus().deleteRow().run(),
          visible: this.editor?.can()?.deleteRow(),
        },
        {
          icon: "mdi-table-row",
          label: this.$t("textEditor.buttons.table.headerRow"),
          active: false,
          value: "headerRow",
          action: () => this.editor.chain().focus().toggleHeaderRow().run(),
          visible: this.editor?.can()?.toggleHeaderRow(),
        },
        {
          icon: "mdi-table-column",
          label: this.$t("textEditor.buttons.table.headerColumn"),
          active: false,
          value: "headerColumn",
          action: () => this.editor.chain().focus().toggleHeaderColumn().run(),
          visible: this.editor?.can()?.toggleHeaderColumn(),
        },
        {
          icon: "mdi-table-merge-cells",
          label: this.$t("textEditor.buttons.table.mergeOrSplitCell"),
          active: false,
          value: "mergeOrSplitCell",
          action: () => this.editor.chain().focus().mergeOrSplit().run(),
          visible: this.editor?.can()?.mergeOrSplit(),
        },
        {
          icon: "mdi-table-remove",
          label: this.$t("textEditor.buttons.deleteTable"),
          active: false,
          value: "deleteTable",
          action: () => this.editor.chain().focus().deleteTable().run(),
          visible: this.editor?.can()?.deleteTable(),
        },
      ].filter((button) => button.visible)
    },

    activeNode() {
      return this
        .nodesButtons
        .find((button) => button.active).map((button) => button.value)
    },

  },
}
