import CharacterCount from "@tiptap/extension-character-count"
import { Plugin, PluginKey } from "@tiptap/pm/state"

const CharacterWordLimit = CharacterCount.extend({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("wordLimit"),
        filterTransaction: (transaction, state) => {
          const limit = this.options.limit
          const limitType = this.options.limitType || "characters"

          if (limitType !== "characters" && limitType !== "words") {
            throw new Error("Limit type must be either \"characters\" or \"words\".")
          }

          // Nothing has changed or no limit is defined. Ignore it.
          if (!transaction.docChanged || limit === 0 || limit === null || limit === undefined) {
            return true
          }

          const oldSize = this.storage[limitType]({ node: state.doc })
          const newSize = this.storage[limitType]({ node: transaction.doc })

          // Everything is in the limit. Good.
          if (newSize <= limit) {
            return true
          }

          // The limit has already been exceeded but will be reduced.
          if (oldSize > limit && newSize > limit && newSize <= oldSize) {
            return true
          }

          // The limit has already been exceeded and will be increased further.
          if (oldSize > limit && newSize > limit && newSize > oldSize) {
            return false
          }

          const isPaste = transaction.getMeta("paste")

          // Block all exceeding transactions that were not pasted.
          if (!isPaste) {
            return false
          }

          // For pasted content, we try to remove the exceeding content.
          const pos = transaction.selection.$head.pos
          const over = newSize - limit
          const from = pos - over
          const to = pos

          // It’s probably a bad idea to mutate transactions within `filterTransaction`
          // but for now this is working fine.
          transaction.deleteRange(from, to)

          // In some situations, the limit will continue to be exceeded after trimming.
          // This happens e.g. when truncating within a complex node (e.g. table)
          // and ProseMirror has to close this node again.
          // If this is the case, we prevent the transaction completely.
          const updatedSize = this.storage[limitType]({ node: transaction.doc })

          if (updatedSize > limit) {
            return false
          }

          return true
        },
      }),
    ]
  },
})

export default CharacterWordLimit
