<template>
  <div>
    <div>
      <v-row
        v-for="row in arryOfIntegers(rowsCount)"
        :key="row"
        no-gutters
      >
        <v-col
          v-for="col in arryOfIntegers(colsCount)"
          :key="col"
          cols="auto"
        >
          <div
            class="create-cell center"
            :class="{'active-cell': isActive(row, col)}"
            @mouseover="setRowsCols(row,col)"
            @click="createTable"
          />
        </v-col>
      </v-row>
    </div>

    <div class="center">
      {{ rows }} x {{ cols }}
    </div>
  </div>
</template>

<script>

export default {
  name: "TableCreator",
  props:{
    rowsCount: { type: Number, default: 7 },
    colsCount: { type: Number, default: 7 },
  },
  data: () => ({
    rows: 1,
    cols: 1,
  }),
  methods: {
    setRowsCols(rows, cols){
      this.rows = rows
      this.cols = cols
    },
    createTable() {
      this.$emit("create-table", { rows: this.rows, cols: this.cols })
    },
  },
  computed: {
    isActive() {
      return (row, col) =>  row <= this.rows && col <= this.cols
    },
    arryOfIntegers() {
      return (count) => Array.from({ length: count }, (v, k) => k + 1)
    },
  },
}
</script>

<style scoped>
  .create-cell {
    width: 26px;
    height: 17px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    margin: 2px;
  }

  .active-cell {
    background: #F0F4FA;
  }
</style>
