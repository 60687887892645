<template>
  <v-menu
    bottom
    offset-y
    min-width="auto"
    nudge-bottom="5px"
    :close-on-content-click="closeOnContentClick"
    v-model="menuIsOpen"
  >
    <template #activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      >
        <v-btn
          small
          text
          class="px-2 ma-1"
          v-on="on"
        >
          <v-icon
            small
            left
          >
            {{ icon }}
          </v-icon>

          {{ label }}

          <v-icon right>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list
      dense
      class="very-dense"
      nav
    >
      <v-list-item
        v-for="item in items"
        :key="item.value"
        @click="item.action"
      >
        <v-list-item-icon class="compact-icon">
          <v-icon small>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="!hideCurrentActive">
          <v-icon
            small
            v-if="item.active"
          >
            mdi-check
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "EditorMenu",
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: [String, null],
      default: null,
    },
    icon: {
      type: [String, null],
      default: null,
    },
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    hideCurrentActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    menuIsOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>

<style scoped>

</style>
